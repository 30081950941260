import { ActionFunctionArgs, LoaderFunctionArgs, json } from '@remix-run/node';
import {
  Form,
  Link,
  useActionData,
  useNavigate,
  useNavigation,
  useSearchParams,
} from '@remix-run/react';

import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { MaxWidth } from '~/components/ui/max-width';
import { Separator } from '~/components/ui/separator';

import { handleEmailLogin, handleSocialLogin } from '~/actions/login/login-actions.server.ts';

import { requireAnonymous } from '~/utils/authentication/authentication.server';
import { invalidFormSubmission } from '~/utils/conform/conform-helper.server.ts';
import {
  FormActionResponse,
  SchemaType,
  getSafeInputProps,
  useLastResult,
} from '~/utils/conform/conform-helper.ts';
import { createEntityId } from '~/utils/db/entity-id';
import { isLoading } from '~/utils/general/is-loading.ts';
import { getAuthenticationSession } from '~/utils/session/authentication.server';

import { useForm } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { redirectWithError } from 'remix-toast';
import { z } from 'zod';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await requireAnonymous(request);
  const authenticationSession = await getAuthenticationSession(request);
  const interactionId = authenticationSession.getInteractionId() ?? createEntityId('interaction');
  authenticationSession.setInteractionId(interactionId);
  return json({ data: null }, { headers: { 'Set-Cookie': await authenticationSession.commit() } });
};

export const emailLoginSchema = z.object({
  intent: z.literal('emailLogin'),
  email: z.string().email(),
});

export const socialLoginSchema = z.object({
  intent: z.literal('socialLogin'),
  provider: z.enum(['tk-auth']),
});
export type SocialLoginSchema = SchemaType<typeof socialLoginSchema>;
export type EmailLoginSchema = SchemaType<typeof emailLoginSchema>;

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: z.discriminatedUnion('intent', [emailLoginSchema, socialLoginSchema]),
  });
  if (submission.status !== 'success') {
    return invalidFormSubmission(submission);
  }
  const authenticationSession = await getAuthenticationSession(request);
  const interactionId = authenticationSession.getInteractionId();
  if (!interactionId) {
    return redirectWithError('/login', context.translation('errors.invalidRequest'));
  }
  const { value, ...rest } = submission;
  switch (value.intent) {
    case 'emailLogin': {
      return handleEmailLogin({
        context,
        submission: { value, ...rest },
        interactionId,
      });
    }
    case 'socialLogin': {
      return handleSocialLogin({
        submission: { value, ...rest },
        context,
        authenticationSession,
      });
    }
  }
};
const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const sent = searchParams.get('sent') === 'true';
  const email = searchParams.get('email');

  const { t } = useTranslation();

  return (
    <div className={'h-screen flex justify-center items-center'}>
      <MaxWidth size={'sm'}>
        <div className={'mx-auto max-w-md p-7 space-y-8'}>
          <hgroup className={'space-y-4 text-center'}>
            <span className={'flex w-full justify-center'}>
              <DashboardIcon />
            </span>
            {!sent && (
              <div>
                <h2 className={'text-2xl font-semibold'}>{t('login.title')}</h2>
                <p className={'text-sm text-muted-foreground'}>{t('login.description')}</p>
              </div>
            )}
          </hgroup>
          {sent && email ? <EmailSent email={email} /> : <EmailInput />}
        </div>
        <span
          className={
            'flex flex-col gap-4 items-center justify-center text-xs text-muted-foreground'
          }>
          <p className={'max-w-md text-center'}>{t('login.disclaimer')}</p>
          <span className={' underline'}>
            <Link to={'https://triargos.de/impressum/'} target={'_blank'}>
              {t('login.imprint')}
            </Link>
          </span>
        </span>
      </MaxWidth>
    </div>
  );
};

const EmailInput = () => {
  const { t } = useTranslation('common');
  const actionData = useActionData<FormActionResponse>();
  const lastResult = useLastResult(actionData);
  const navigation = useNavigation();

  const [form, fields] = useForm({
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: emailLoginSchema });
    },
    defaultValue: {
      intent: 'emailLogin',
    },
    lastResult,
  });

  return (
    <section className={'space-y-4'}>
      <Form
        id={form.id}
        onSubmit={form.onSubmit}
        method={'post'}
        className={'mt-4 w-full space-y-4'}>
        <input type={'hidden'} name={fields.intent.name} value={fields.intent.initialValue} />
        <span className={'grid gap-2'}>
          <Label>{t('login.form.email.label')}</Label>
          <Input
            className={'h-11'}
            {...getSafeInputProps(fields.email, { type: 'text' })}
            placeholder={t('login.form.email.placeholder')}></Input>
        </span>
        <Button isLoading={isLoading(navigation, 'emailLogin')} className={'w-full h-11'}>
          {t('login.button')}
        </Button>
      </Form>
      <Separator />
      <Form method={'post'} className={'mt-4 w-full'}>
        <input type='hidden' name={fields.intent.name} value={'socialLogin'} />
        <input type='hidden' name={'provider'} value={'tk-auth'} />
        <Button
          isLoading={isLoading(navigation, 'socialLogin')}
          variant={'secondary'}
          className={'w-full border text-tkbrand'}>
          <>
            <img className={'h-6 w-6'} src={'/images/tk_logo.png'} alt={'tk-logo'} />
            {t('socialLogin.tkAuth')}
          </>
        </Button>
      </Form>
    </section>
  );
};

const EmailSent = ({ email }: { email: string }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('channels:login');
    broadcastChannel.onmessage = (event) => {
      if (event.data?.message === 'login.complete') {
        broadcastChannel.postMessage({ message: 'redirect.confirm' });
        const redirectHint = event.data?.redirectHint ?? '';
        navigate(`/${redirectHint}`);
      }
    };
    return () => {
      broadcastChannel.close();
    };
  }, []);

  return (
    <div className={'text-center'}>
      <h2 className={'text-2xl font-semibold'}>{t('login.sent.title')}</h2>
      <p className={'text-sm text-muted-foreground'}>{t('login.sent.description', { email })}</p>
    </div>
  );
};

export default LoginPage;

const DashboardIcon = () => {
  return (
    <div
      className={
        'rounded-md bg-primary h-8 w-8 flex items-center justify-center text-primary-foreground font-medium text-sm'
      }>
      DB
    </div>
  );
};
